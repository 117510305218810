.project-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.project-detail-content-container {
  width: 60%;
}

.project-detail-header {
  display: flex;
  align-items: baseline;
  margin: 0;
  flex-wrap: wrap;
}

.techstack-container {
  margin: 0.75em 0 0 0;
}

.overview-container {
  margin: 2em 0 0 0;
}

.screenshot-container {
  margin: 2em 0 0 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.screenshot-container img {
  min-width: 300px;
  min-height: 400px;
  max-width: 400px;
  max-height: 533px;
  margin: 0.5em;
}

.description-container p {
  margin: 1em 0 0 0;
}
