.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}

.outer-page-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.page-container {
  height: 85%;
  width: 80%;
  margin: 0;
  padding: 1em 1em 1em 1em;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  line-height: 1em;
}

.text-link {
  border-bottom: 2px solid var(--color-foreground);
  cursor: pointer;
  transition: border-color 0.3s ease-in;
}
