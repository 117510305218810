.header {
  display: flex;
  width: 80%;
  margin: 2rem 0 0 0;
}

.iconButton {
  overflow: hidden;
  padding: 0;
  margin: 0;
  width: 64px;
  height: 64px;
  border-radius: 100px;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  border: 0;
  transition: transform 0.3s ease;
}

.iconButton:hover {
  cursor: pointer;
  transform: rotate(-45deg);
}

.iconImg {
  width: 64px;
  height: 64px;
}

.nav-button-container {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 3em 0 1em 0;
}

.nav-back-button {
  display: flex;
  align-items: center;
}
.nav-back-button:hover {
  cursor: pointer;
}
