.project-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.project-item-container {
  width: 60%;
  margin: 1.5em 0 0 0;
}

.project-item-header {
  padding: 1em 0 1em 0;
  display: flex;
}

.project-links-container {
  margin: 0 0 0 1em;
}

.project-item-button-container {
  display: flex;
  flex-direction: row-reverse;
}
