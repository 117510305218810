.transition-image {
  max-width: 64px;
  max-height: 64px;
  transition: opacity 0.3s ease-in;
}

.fade-out {
  opacity: 0;
}

.fade-in {
  opacity: 1;
}
