.footer-container {
  width: 30%;
  margin: 35px 0 0 0;
}

.dog-bowl-container {
  display: flex;
  justify-content: center;
}

.dog-bowl-container img:hover {
  cursor: pointer;
}

.dog-bowl-container span {
  height: 17px;
  margin: 0;
  padding: 0;
}

hr {
  background-color: var(--color-foreground);
  height: 1px;
  border: 0;
  transition: background-color 0.3s ease-in;
}
