.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3em 0 1.75em 0;
}
.landing-image {
  width: 500px;
  height: 375px;
}

.image-caption {
  font-size: 0.5em;
  font-style: italic;
  font-weight: 300;
  margin: 3px 0 0 0;
}

.work-container {
  margin: 1.25em 0 0 0;
}

.contact-container {
  margin: 1.25em 0 0 0;
}

.current-container {
  margin: 1.25em 0 0 0;
}

.links-container {
  margin: 2.5em 0 0 0;
}
