body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Karla", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background);
  color: var(--color-foreground);
  font-size: 1.25em;
  scroll-behavior: smooth;
  letter-spacing: 0.1rem;
}

body {
  --color-background: #161718;
  --color-foreground: #ffffff;
  --color-scroll-gutter: #1b1b1b;
  transition: background-color 0.3s ease-in, color 0.3s ease-in,
    border-color 0.3s ease-in;
}

@media (prefers-color-scheme: light) {
  body {
    --color-background: #fbf2e9;
    --color-foreground: #261911;
    --color-scroll-gutter: #f6f1e4;
  }
}

h1 {
  font-family: "Spectral", serif;
  font-weight: 800;
  font-size: 3.5em;
  font-style: italic;
  padding: 0;
  margin: 0;
  text-align: start;
}

h2 {
  font-family: "Spectral", serif;
  font-weight: 800;
  font-size: 2.5em;
  font-style: italic;
  padding: 0;
  margin: 0;
  text-align: start;
  display: inline-block;
}

a {
  color: var(--color-foreground);
  text-decoration: none;
  border-bottom: 2px solid var(--color-foreground);
  transition: border-color 0.3s ease-in, color 0.3s ease-in;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-scroll-gutter);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-foreground);
  border-radius: 10px;
  height: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  box-shadow: 1px 1px 1px 1px rgba(255, 255, 255, 0.34);
}
